.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.logo {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
}

/* 메뉴 아이템에 공통적으로 적용할 스타일 */
.menu-item-home {
  margin-left: 20px;
}

/* 햄버거 버튼 스타일 */
.menu-toggle-button {
  display: none; /* 기본적으로 숨김 */
  border: 2px solid #ddd;
  height: 37px
}

.no-scrollbar {
  overflow: auto;
  height: 100%;
}

@media screen and (max-width: 540px) {
  .menu-item, .menu-main {
    display: none; /* 모든 메뉴 아이템과 메인 메뉴 숨김 */
  }
  .menu-toggle-button {
    display: block; /* 햄버거 버튼만 표시 */
  }
}

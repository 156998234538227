/* src/App.css */
.App {
  text-align: center;
}

html {
  font-family: var(--font-family);
}


body { 
  margin: 0;
}

/* 전체 문서에 글자 굵기 및 폰트 적용 */
:root {
  font-weight: var(--font-weight, normal);
}

/* 버튼에 대한 별도의 굵은 글자 스타일 */
.bold-text {
  font-weight: bold; 
}

/*src/pages/DarkMode.css */


.dark-mode {
  background-color: #333; /* 다크 모드 배경색 */
  color: #fff; /* 다크 모드 텍스트 색상 */
}

.dark-mode .ant-card ,
.dark-mode .ant-table-cell
{
  background-color: #333; 
  color: #fff; 
  border: solid #d0d0d0;
}

.dark-mode .ant-card input::placeholder,
.dark-mode .ant-card textarea::placeholder,
.dark-mode .ant-drawer-title
{
  color: #fff;
}

.dark-mode .ant-radio-group,
.dark-mode .ant-radio-wrapper,
.dark-mode .ant-tabs-tab-btn,
.dark-mode .ant-typography,
.dark-mode .ant-list-item,
.dark-mode .ant-modal-content,
.dark-mode .ant-modal-title,
.dark-mode .ant-table,
.dark-mode .ant-list-item
.dark-mode .ant-menu-item-group,
.dark-mode .ant-table-thead > tr > th,
.dark-mode .ant-table-tbody > tr > td 
{
  background-color: #333;
  color: #fff;
}


.dark-mode .ant-drawer-content,
.dark-mode .ant-drawer-header,
.dark-mode .ant-menu,
.dark-mode .ant-menu-item-group-title
{
  background-color: #333;
  color: #fff;
}
